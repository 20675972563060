import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import NiftyTotal from './TableData.jsx/NiftyTotalMarkt';


const Home = () => {
    return (
        <Router>
            <div>
                <div style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'white' }}>
                    <Navbar />
                </div>
                <div className="p-4">
                    <Routes>
                        <Route path="/" element={<Navigate to="/nifty-total-market" />} />
                        <Route path="/nifty-total-market" element={<NiftyTotal />} />                        
                        <Route path="*" element={<div>404 - Page Not Found</div>} />
                    </Routes>
                   
                </div>
            </div>
        </Router>
    );
};

export default Home;
